import { Attachment } from '@pa/lib-spa/types';
import { DocumentationLink } from '@pa/sdk/idf';

export const mapDocumentationLinks = (documents?: DocumentationLink[]): Attachment[] => {
    return (documents ?? [])?.map((doc) => {
        const attachment: Attachment = {
            fileName: doc.filename,
            url: doc.url,
            pdf: '',
            attachmentId: '',
            loading: false,
        };
        return attachment;
    });
};
