<div>
    <h1>Underwriting</h1>
    <mat-card class="mb-3">
        <mat-card-header>
            <mat-card-title> Quote Information </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="loadingProposal">
            <div class="row justify-content-center">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </mat-card-content>
        <mat-card-content *ngIf="!loadingProposal">
            <table class="mat-table">
                <tr class="mat-row">
                    <th class="mat-header-cell">Transaction Type:</th>
                    <td class="mat-cell">
                        {{ transactionType }}
                    </td>
                </tr>
                <tr class="mat-row">
                    <th class="mat-header-cell">Quote Status:</th>
                    <td class="mat-cell">
                        {{ quoteStatus }}
                    </td>
                </tr>
                <tr class="mat-row">
                    <th class="mat-header-cell">CS Actions:</th>
                    <td class="mat-cell">
                        <div *ngIf="loadingAction" class="row m-5">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div *ngIf="!loadingAction">
                            <mat-card-content class="links">
                                <a
                                    *ngIf="showEditProposalLink"
                                    [attr.href]="editProposalLink"
                                    target="_blank"
                                    data-testid="editProposal"
                                >
                                    Edit Proposal
                                </a>
                            </mat-card-content>
                        </div>
                    </td>
                </tr>
                <tr class="mat-row">
                    <th class="mat-header-cell">Reason for Referral:</th>
                    <td class="mat-cell">
                        <div *ngIf="referrals.assetReferrals.length">
                            <strong>Assets</strong>
                            <ul>
                                <li *ngFor="let referral of referrals.assetReferrals">
                                    {{ referral.description }}
                                    <ol>
                                        <li *ngFor="let referralReason of referral.referralReasons">
                                            {{ referralReason.reason }}
                                            <ul *ngIf="referralReason?.subReasons?.length">
                                                <li *ngFor="let sr of referralReason?.subReasons">{{ sr }}</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                            </ul>
                        </div>
                        <div *ngIf="referrals.policyReferrals.length">
                            <strong>Policy</strong>
                            <ul>
                                <li *ngFor="let referral of referrals.policyReferrals">
                                    {{ referral.description }}: {{ referral.referralReason }}
                                    <ol *ngIf="referral.detailedReasons.length">
                                        <li *ngFor="let detailedReason of referral.detailedReasons; index as i">
                                            {{ detailedReason }}
                                            <ul *ngIf="referral.additionalDetails[i]?.length">
                                                <li>
                                                    {{ referral.additionalDetails[i] }}
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                            </ul>
                        </div>
                    </td>
                </tr>
                <tr class="mat-row" *ngIf="statusFlags.display">
                    <th class="mat-header-cell">Status Flags:</th>
                    <td class="mat-cell">
                        <div *ngIf="statusFlags.loading" class="row justify-content-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div *ngIf="!statusFlags.loading">
                            <div *ngFor="let flagType of statusFlags.flagTypes">
                                <strong>{{ flagType.title }}</strong>
                                <ul>
                                    <li *ngFor="let flag of flagType.flags">
                                        {{ flag.identifier }}
                                        <ul>
                                            <li *ngFor="let detail of flag.details">{{ detail }}</li>
                                            <li *ngIf="flag.additionalDetails">
                                                Additional Details:
                                                <ul>
                                                    <li *ngFor="let addDetail of flag.additionalDetails">
                                                        {{ addDetail }}
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>

    <mat-card class="mb-3">
        <mat-card-header>
            <mat-card-title> Policy Wide Details </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="loadingProposal">
            <div class="row justify-content-center">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </mat-card-content>
        <mat-card-content *ngIf="!loadingProposal">
            <table class="mat-table">
                <tr class="mat-row">
                    <th class="mat-header-cell">Insured Name:</th>
                    <td class="mat-cell">
                        {{ model.company?.companyName }}
                    </td>
                    <th class="mat-header-cell" *ngIf="!loadingProposal && isHybridOriginator">Insured Contact:</th>
                    <td class="mat-cell" data-testid="showHybridData">
                        <tr class="mat-row" data-testid="contact">
                            {{
                                (model.contact?.givenName || '') + ' ' + (model.contact?.familyName || '')
                            }}
                        </tr>

                        <tr class="mat-row">
                            {{
                                model.contact?.emailAddress
                            }}
                        </tr>
                        <tr class="mat-row">
                            {{
                                model.contact?.phoneNumber
                            }}
                        </tr>
                    </td>
                </tr>
                <tr *ngIf="model.inceptionDate" class="mat-row">
                    <th class="mat-header-cell">Inception Date:</th>
                    <td class="mat-cell">
                        <pa-format-date [dateConfig]="{ date: model.inceptionDate }"></pa-format-date>
                    </td>
                </tr>
                <tr *ngIf="model.amendmentEffectiveDate" class="mat-row">
                    <th class="mat-header-cell">Amendment Effective Date:</th>
                    <td class="mat-cell">
                        <pa-format-date [dateConfig]="{ date: model.amendmentEffectiveDate }"></pa-format-date>
                    </td>
                </tr>
                <tr *ngIf="model.cancellationEffectiveDate" class="mat-row">
                    <th class="mat-header-cell">Cancellation Effective Date:</th>
                    <td class="mat-cell">
                        <pa-format-date [dateConfig]="{ date: model.cancellationEffectiveDate }"></pa-format-date>
                    </td>
                </tr>
                <tr *ngIf="clientPolicy?.expiryDate" class="mat-row">
                    <th class="mat-header-cell">Expiry Date:</th>
                    <td class="mat-cell">
                        <pa-format-date
                            [dateConfig]="{ date: clientPolicy.expiryDate, timezone: model.timezone, }"
                        ></pa-format-date>
                    </td>
                </tr>
                <tr *ngIf="isHybridOriginator" class="mat-row" data-testid="showHybridData">
                    <th class="mat-header-cell">Broker Details:</th>
                    <td class="mat-cell">
                        <tr>
                            {{
                                originatorType
                            }}
                        </tr>
                        <tr>
                            {{
                                (broker?.firstName || '') + ' ' + (broker?.lastName || '')
                            }}
                        </tr>
                        <tr>
                            {{
                                broker?.emailAddress
                            }}
                        </tr>
                        <tr>
                            {{
                                broker?.phoneNumber
                            }}
                        </tr>
                    </td>
                </tr>
                <tr class="mat-row">
                    <th class="mat-header-cell">Policy Wide interested Parties:</th>
                    <td class="mat-cell">
                        <mat-list-item *ngFor="let ip of model.clientProposalInterestedParties">{{ ip }}</mat-list-item>
                    </td>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>

    <form [formGroup]="form">
        <formly-form #formlyForm [model]="model" [form]="uForm" [fields]="fields"></formly-form>

        <!-- PP-3666 Referral UI subjectivity's Formatting -->
        <mat-card class="mb-5" *ngIf="notes.length">
            <mat-card-content>
                <ol>
                    <li *ngFor="let note of notes; let i = index">
                        <div class="row my-1">
                            <div class="col-11" *ngIf="isNoteDisabled">
                                <p style="white-space: pre-wrap">{{ note }}</p>
                            </div>
                            <div class="col-11" *ngIf="!isNoteDisabled">
                                <textarea class="form-control Notedisabled" (change)="notes[i] = $event.target.value">{{
                                    note
                                }}</textarea>
                            </div>
                            <div class="col-1">
                                <button class="btn btn-primary btn-sm" (click)="deleteNote(i)">
                                    <i class="fal fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </li>
                </ol>
                <button mat-flat-button title="Edit" color="primary" (click)="editNote()">
                    <i class="fal fa-pencil"></i>
                    <span class="pl-2">{{ EditSave }}</span>
                </button>
            </mat-card-content>
        </mat-card>

        <edit-asset-modal #editAssetModal (modelDataChanged)="onAssetQuoteDataChange($event)"></edit-asset-modal>
        <confirm-dialog #confirmDialog confirmBtnLabel="Accept" (confirmClicked)="accept()"></confirm-dialog>
        <div class="row pb-5">
            <div class="col-md-12 text-right" *ngIf="showUnderwritingButton">
                <button
                    [disabled]="processing"
                    mat-raised-button
                    color="Warn"
                    (click)="openDeclineModal()"
                    data-testid="open-decline-modal"
                >
                    <i *ngIf="processing && action === 'decline'" class="fal fa-spinner-third fa-spin"></i>
                    Decline
                </button>
                <button
                    [disabled]="!isValidToAccept"
                    mat-raised-button
                    type="button"
                    class="ml-2 btn btn-primary"
                    (click)="openAcceptModal()"
                    data-testid="open-accept-modal"
                >
                    <i *ngIf="processing && action === 'accept'" class="fal fa-spinner-third fa-spin"></i>
                    Accept
                </button>
            </div>
        </div>
    </form>
</div>
