import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { Auth } from 'aws-amplify';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(Auth.currentSession()).pipe(
            switchMap((auth: any) => {
                const withAuthRequest = request.clone({
                    setHeaders: {
                        authorization: `Bearer ${auth.accessToken.jwtToken}`,
                    },
                });
                return next.handle(withAuthRequest);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }
}
