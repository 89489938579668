import { AssetQuote, Behaviour, ClientProposal, ClientQuote, Organisation, Originator } from '@pa/sdk/idf';
import { BehaviourService, mapAttachments } from '@pa/lib-spa';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FeatureFlags, OriginatorType, QuoteStatus, TransactionType } from '@pa/references/idf';
import { forkJoin } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { Attachment, FormatDateConfig } from '@pa/lib-spa/types';
import { AuthService } from '../core/auth/auth.service';
import { ConfirmDialogComponent } from '../components/dialogs/confirm-dialog.component';
import { PolicyService } from '../services/policy.service';
import { QuoteService } from '../services/quote.service';
import { SdkService } from './../services/sdk.service';
import { mapDocumentationLinks } from '../core/utils/attachment.mapping';
import { switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
import { CustomerActionsComponents, CustomerActionsService } from '../services/customer-actions.service';
import { Auth } from 'aws-amplify';

@Component({
    selector: 'app-view-quote',
    templateUrl: './view-quote.component.html',
    styleUrls: ['./view-quote.component.scss'],
})
export class ViewQuoteComponent implements OnInit {
    @ViewChild('confirmDialog') confirmDialog: ConfirmDialogComponent;
    @ViewChild('reassignBrokerDialog') reassignBrokerDialog: ConfirmDialogComponent;
    @ViewChild('responseDialog') responseDialog: ConfirmDialogComponent;

    private loginPortalURI = environment.apiBaseUrls.loginPortal;

    assetQuotes: AssetQuote[];
    attachments: Attachment[];
    behaviour: Behaviour;
    behaviourId: string = '';
    clientProposal: ClientProposal;
    clientQuote: ClientQuote;
    enabledFeatureFlags: FeatureFlags[];
    organisation: Organisation;
    organisationOriginators: Originator[] = [];
    originator: Originator;
    policyNumber: string;
    proposalId: string = '';
    proposedEffectiveDate: string;
    referralPageLink: string = '';
    editProposalLink: string = '';
    selectedOriginatorCode: string;
    serviceActionEvent?: FeatureFlags = undefined;
    timezone: string = 'UTC';
    transactionQuoteName: string = 'Quote';
    datesConfig: { [date: string]: FormatDateConfig } = {};

    error: boolean = false;
    hideReassignBrokerLink: boolean = true;
    hideRegenerateLink: boolean = true;
    hideResendLink: boolean = true;
    showEditProposalLink: boolean = false;
    isNB: boolean = false;
    isReferral: boolean = false;
    loading: boolean = true;
    loadingAction: boolean = true;
    loadingDetails: boolean = false;
    showBroker: boolean = false;
    hasClientQuoteHoldCover: boolean = false;

    constructor(
        private router: ActivatedRoute,
        private location: Location,
        private quoteService: QuoteService,
        private behaviourService: BehaviourService,
        private policyService: PolicyService,
        private authService: AuthService,
        private sdk: SdkService,
        private customerActionsService: CustomerActionsService
    ) {}

    ngOnInit(): void {
        this.loading = true;
        Auth.currentSession()
            .then((res) => res.getAccessToken().getJwtToken())
            .then((accessToken) => {
                this.sdk.cmf.accessToken = accessToken;
                this.sdk.idf.accessToken = accessToken;
            });

        this.router.queryParams
            .pipe(
                switchMap((params) => {
                    this.proposalId = params.proposalId;
                    this.behaviourId = params.behaviourId;

                    return forkJoin([
                        this.quoteService.getBehaviour(this.behaviourId),
                        this.quoteService.getProposal(this.proposalId, this.behaviourId),
                    ]);
                })
            )
            .subscribe({
                next: async ([behaviour, proposal]) => {
                    this.behaviour = behaviour;
                    this.behaviourService.set(behaviour);
                    this.clientProposal = proposal;
                    this.timezone = proposal.timezone ?? 'UTC';

                    this.datesConfig['proposalInceptionDate'] = {
                        date: this.clientProposal.inceptionDate,
                        timezone: this.clientProposal.timezone,
                    };

                    this.clientQuote = this.clientProposal.clientQuote;
                    this.originator = this.clientProposal.originator as Originator;
                    this.assetQuotes = this.clientQuote.assetQuotes as AssetQuote[];

                    this.datesConfig['quoteExpiryDate'] = {
                        date: this.clientQuote.expiryDate,
                        timezone: this.clientProposal.timezone,
                    };
                    this.datesConfig['quoteReferralDecisionDate'] = {
                        date: this.clientQuote.referralDecision?.date,
                        timezone: this.clientProposal.timezone,
                    };

                    this.hasClientQuoteHoldCover =
                        this.clientQuote.holdCoverProposal && this.clientQuote.holdCoverProposal.reasons.length
                            ? true
                            : false;
                    this.isNB = this.clientProposal.transactionType === TransactionType.newBusiness;
                    this.showBroker =
                        this.originator.type === OriginatorType.intermediary ||
                        this.originator.type === OriginatorType.insuredPolicyManager;
                    this.isReferral =
                        [
                            QuoteStatus.dirty,
                            QuoteStatus.filthy,
                            QuoteStatus.coverageHeldDirty,
                            QuoteStatus.coverageHeldFilthy,
                        ].includes(this.clientQuote.status) ||
                        this.clientQuote.referrals?.length > 0 ||
                        this.assetQuotes.some((assetQuote) => assetQuote.referrals.length > 0);

                    this.proposedEffectiveDate = this._getProposedEffectiveDate(this.clientProposal);
                    this.datesConfig['proposedEffectiveDate'] = {
                        date: this.proposedEffectiveDate,
                        timezone: this.clientProposal.timezone,
                    };

                    const documentLinks: Attachment[] = mapDocumentationLinks(this.clientProposal.documentationLinks);
                    this.attachments = mapAttachments(
                        documentLinks,
                        this.behaviour,
                        proposal.clientQuote.transactionType
                    );

                    if (this.clientProposal.clientPolicy) {
                        const res = await this.policyService
                            .getPolicyHistory(this.clientProposal.clientPolicy, this.behaviourId)
                            .toPromise();
                        this.policyNumber = res?.reference;
                        if (res?.latestRevision) {
                            this.policyNumber += ` Revision ${res.latestRevision}`;
                        }
                    }

                    this.referralPageLink = `/underwriting?proposalId=${this.proposalId}&behaviourId=${this.behaviourId}`;
                    this.editProposalLink = `${this.loginPortalURI}/pp/?proposalId=${this.proposalId}&behaviour=${this.behaviourId}&withSession=true`;

                    this.transactionQuoteName = this._getTransactionQuoteName();
                    this.loading = false;
                    this.error = false;

                    this.customerActionsService
                        .getCustomerServiceActionLinks(
                            CustomerActionsComponents.viewQuote,
                            this.proposalId,
                            this.behaviourId,
                            this.clientProposal,
                            this.clientQuote,
                            this.policyNumber,
                            this.originator
                        )
                        .subscribe((res) => {
                            this.loadingAction = false;
                            this.showEditProposalLink = res.showEditProposal;
                            this.hideReassignBrokerLink = res.hideReassignBroker;
                            this.hideRegenerateLink = res.hideRegenerate;
                            this.hideResendLink = res.hideResend;
                        });
                },
                error: (err) => {
                    this.loading = false;
                    this.error = true;
                },
            });
    }

    backToSearch() {
        this.location.back();
    }

    formatNameFromEmail(name: string): string {
        if (!name) {
            return '';
        }
        return name.split('@')[0];
    }

    private _getProposedEffectiveDate(clientProposal: ClientProposal): string {
        switch (clientProposal.transactionType) {
            case TransactionType.newBusiness:
                return clientProposal.inceptionDate;
            case TransactionType.amendment:
                return clientProposal.amendmentEffectiveDate;
            case TransactionType.renewal:
                return clientProposal.renewalEffectiveDate;
            case TransactionType.cancellation:
                return clientProposal.cancellationEffectiveDate;
            default:
                const _check: never = clientProposal.transactionType;
                throw new Error('Unknown transaction type: ' + clientProposal.transactionType);
        }
    }

    private _getTransactionQuoteName(): string {
        switch (this.clientProposal.transactionType) {
            case TransactionType.newBusiness:
                return 'Quote';
            case TransactionType.amendment:
                return 'Amendment Offer';
            case TransactionType.renewal:
                return 'Renewal Offer';
            case TransactionType.cancellation:
                return 'Cancellation Offer';
            default:
                const _check: never = this.clientProposal.transactionType;
                return;
        }
    }

    openRegenerateAcceptModal() {
        this.serviceActionEvent = FeatureFlags.insurerRegenerateDocuments;
        this.confirmDialog.open(
            `Please confirm you want to Regenerate ${this.transactionQuoteName} Documentation`,
            'Please Confirm!'
        );
        return false;
    }

    openResendAcceptModal() {
        this.serviceActionEvent = FeatureFlags.insurerResendNotification;
        this.confirmDialog.open(
            `Please confirm you want to resend ${this.transactionQuoteName} email`,
            'Please Confirm!'
        );
        return false;
    }

    openReassignBrokerModal() {
        this.serviceActionEvent = FeatureFlags.reAssignBroker;
        const organisationId =
            typeof this.originator.organisation === 'string'
                ? this.originator.organisation
                : this.originator.organisation._id;

        if (this.organisationOriginators?.length === 0) {
            this.loadingDetails = true;
            this.sdk.idf.Organisations.get({ organisationId, originator: 'true', behaviour: this.behaviourId }).then(
                (organisation) => {
                    this.organisation = organisation[0];
                    this.organisationOriginators = (organisation[0].originators as unknown as Originator[])
                        .filter((originator) => originator.type === OriginatorType.intermediary)
                        .sort((a, b) => (a.individual > b.individual ? 1 : -1));
                    this.selectedOriginatorCode = this.organisationOriginators.find(
                        (o) => o.individual === this.originator.individual
                    )?.code;
                    this.loadingDetails = false;
                }
            );
        }
        this.reassignBrokerDialog.open(`Please confirm who you'd like to Reassign this account to`, 'Reassign Broker');
        return false;
    }

    confirmReassignBroker() {
        this.confirmDialog.open(
            `Once Reassigned, the Broker will automatically receive an updated ${this.transactionQuoteName} Email`,
            ''
        );
    }

    acceptServiceAction(event: FeatureFlags) {
        this.loadingAction = true;

        // Note: accessToken for idf service is already set in app-component.
        let successMessage = 'Success';
        let errorMessage = 'An error occurred - the platform support team has been notified and will be in touch.';
        let eventPromise: Promise<any>;

        if (event === FeatureFlags.insurerRegenerateDocuments) {
            successMessage = `${this.transactionQuoteName} documentation has successfully generated and the ${this.transactionQuoteName} email has been resent`;
            errorMessage = `An error occurred when regenerating the ${this.transactionQuoteName} Documentation - the platform support team has been notified and will be in touch.`;
            eventPromise = this.sdk.idf.Quotes.regenerateDocuments(
                this.behaviourId,
                this.clientProposal.transactionType,
                this.clientProposal._id
            );
        } else if (event === FeatureFlags.insurerResendNotification) {
            successMessage = `${this.transactionQuoteName} Email has successfully been resent.`;
            eventPromise = this.sdk.idf.Quotes.notification(
                this.behaviourId,
                this.clientProposal._id,
                this.originator._id
            );
        } else if (event === FeatureFlags.reAssignBroker) {
            successMessage = `${this.transactionQuoteName} Email has successfully been sent.`;
            eventPromise = this.sdk.idf.Originators.reassignBroker(this.behaviourId, this.selectedOriginatorCode, {
                clientProposal: this.clientProposal._id,
            });
        }

        eventPromise
            .then(() => {
                this.loadingAction = false;
                this.responseDialog.open(successMessage);
            })
            .catch(() => {
                this.loadingAction = false;
                this.responseDialog.open(errorMessage);
            });
    }

    closeClicked() {
        return window.location.reload();
    }
}
