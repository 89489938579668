<div *ngIf="loading" class="row justify-content-center">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="!loading" class="mb-5">
    <div id="title-bar">
        <span class="col-md-2">
            <a data-testid="back-to-search-results" (click)="backToSearch()"> << Back to search results</a>
        </span>
        <span class="col-md-8">
            <h2>Transaction Type: {{ clientProposal.transactionType | titlecase }}</h2>
        </span>
        <span class="col-md-2" hidden></span>
    </div>
    <mat-card class="my-3 px-3">
        <div class="row">
            <div class="col-4">
                <mat-card-header>
                    <mat-card-title>Quote Details</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table class="mat-table">
                        <tr>
                            <th class="mat-header-cell">Insured Name</th>
                            <td class="mat-cell" data-testid="InsuredName">
                                {{ clientProposal.company.companyName }}
                            </td>
                        </tr>
                        <tr>
                            <th class="mat-header-cell">Quote Date/Time</th>
                            <td class="mat-cell" data-testid="quoteDateTime">
                                <pa-format-date [dateConfig]="datesConfig.quoteReferralDecisionDate"></pa-format-date>
                            </td>
                        </tr>
                        <tr *ngIf="!isNB">
                            <th class="mat-header-cell">Policy Number</th>
                            <td class="mat-cell" data-testid="policyNumber">
                                {{ policyNumber }}
                            </td>
                        </tr>
                        <tr *ngIf="isNB">
                            <th class="mat-header-cell">Proposed Inception Date</th>
                            <td class="mat-cell" data-testid="proposedInceptionDate">
                                <pa-format-date [dateConfig]="datesConfig.proposalInceptionDate"></pa-format-date>
                            </td>
                        </tr>
                        <tr *ngIf="!isNB">
                            <th class="mat-header-cell">Inception Date</th>
                            <td class="mat-cell" data-testid="inceptionDate">
                                <pa-format-date [dateConfig]="datesConfig.proposalInceptionDate"></pa-format-date>
                            </td>
                        </tr>
                        <tr *ngIf="!isNB">
                            <th class="mat-header-cell">Proposed effective Date</th>
                            <td class="mat-cell" data-testid="proposedEffectiveDate">
                                <pa-format-date [dateConfig]="datesConfig.proposedEffectiveDate"></pa-format-date>
                            </td>
                        </tr>
                        <tr *ngIf="!isNB">
                            <th class="mat-header-cell">Expiry Date</th>
                            <td class="mat-cell" data-testid="expiryDate">
                                <pa-format-date [dateConfig]="datesConfig.quoteExpiryDate"></pa-format-date>
                            </td>
                        </tr>
                        <tr *ngIf="showBroker">
                            <th class="mat-header-cell">Broker Name</th>
                            <td class="mat-cell" data-testid="brokerName">
                                {{ originator.individual }}
                            </td>
                        </tr>
                        <tr *ngIf="clientProposal.interestedParties.length">
                            <th class="mat-header-cell">Policy-Wide Interested Parties</th>
                            <td class="mat-cell">
                                <mat-list-item *ngFor="let ip of clientProposal.interestedParties">
                                    {{ ip.name }}
                                </mat-list-item>
                            </td>
                        </tr>
                        <tr>
                            <th class="mat-header-cell">Hold cover</th>
                            <td class="mat-cell">
                                <ng-container
                                    *ngIf="hasClientQuoteHoldCover; then showQuoteHoldCover; else showProposalHoldCover"
                                >
                                </ng-container>
                                <ng-template #showQuoteHoldCover>
                                    <mat-list-item
                                        data-testid="holdCover"
                                        *ngFor="let reason of clientQuote.holdCoverProposal.reasons"
                                    >
                                        {{ reason }}
                                    </mat-list-item>
                                </ng-template>
                                <!-- Deprecated : PP-6883 - Displaying holdcover moved to holdCoverProposal inside clientQuote -->
                                <ng-template #showProposalHoldCover>
                                    <mat-list-item
                                        data-testid="holdCover"
                                        *ngFor="let reason of clientProposal.holdCoverReasons"
                                    >
                                        {{ reason }}
                                    </mat-list-item>
                                </ng-template>
                                <!--  -->
                            </td>
                        </tr>
                    </table>
                </mat-card-content>
            </div>
            <div class="col-4">
                <mat-card-header>
                    <mat-card-title>Referral Details</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table class="mat-table">
                        <tr>
                            <th class="mat-header-cell">Referral</th>
                            <td class="mat-cell" data-testid="referral">
                                {{ isReferral ? 'Yes' : 'No' }}
                            </td>
                        </tr>
                        <tr *ngIf="isReferral">
                            <th class="mat-header-cell">Referral Date</th>
                            <td class="mat-cell" data-testid="referralDate">
                                <pa-format-date [dateConfig]="datesConfig.quoteReferralDecisionDate"></pa-format-date>
                            </td>
                        </tr>
                        <tr *ngIf="isReferral">
                            <th class="mat-header-cell">Referral Status</th>
                            <td class="mat-cell" data-testid="referralStatus">
                                {{ clientQuote.status | titlecase }}
                                <span *ngIf="clientQuote.referralDecision?.by">
                                    by {{ formatNameFromEmail(clientQuote.referralDecision?.by) }}
                                </span>
                            </td>
                        </tr>
                        <tr *ngIf="isReferral">
                            <td class="mat-cell">
                                <a
                                    mat-raised-button
                                    [attr.href]="referralPageLink"
                                    target="_blank"
                                    style="text-decoration: none"
                                >
                                    Referral page
                                </a>
                            </td>
                        </tr>
                    </table>
                </mat-card-content>
            </div>
            <div class="col-4">
                <mat-card-header>
                    <mat-card-title>Customer Service Actions</mat-card-title>
                </mat-card-header>
                <div *ngIf="loadingAction" class="row m-5">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div *ngIf="!loadingAction">
                    <mat-card-content>
                        <a
                            *ngIf="!hideRegenerateLink"
                            href="#"
                            (click)="openRegenerateAcceptModal()"
                            data-testid="regenerateDocuments"
                        >
                            Regenerate {{ transactionQuoteName }} Documentation ->
                        </a>
                    </mat-card-content>
                    <mat-card-content>
                        <a
                            *ngIf="!hideResendLink"
                            href="#"
                            (click)="openResendAcceptModal()"
                            data-testid="resendNotification"
                        >
                            Resend {{ transactionQuoteName }} Email ->
                        </a>
                    </mat-card-content>
                    <mat-card-content>
                        <a
                            *ngIf="!hideReassignBrokerLink"
                            href="#"
                            (click)="openReassignBrokerModal()"
                            data-testid="reassignBroker"
                        >
                            Reassign Broker ->
                        </a>
                    </mat-card-content>
                    <mat-card-content>
                        <a
                            *ngIf="showEditProposalLink"
                            [attr.href]="editProposalLink"
                            target="_blank"
                            data-testid="editProposal"
                        >
                            Edit Proposal ->
                        </a>
                    </mat-card-content>
                </div>
            </div>
        </div>
    </mat-card>
    <div class="row my-3 mx-2">
        <h2>Documentation Links</h2>
        <table>
            <li *ngFor="let attachment of attachments">
                <a [href]="attachment.url" target="_blank"> {{ attachment.fileName }}</a>
            </li>
        </table>
    </div>
</div>

<confirm-dialog
    #confirmDialog
    title="Please Confirm!"
    confirmBtnLabel="Accept"
    (confirmClicked)="acceptServiceAction(serviceActionEvent)"
></confirm-dialog>
<confirm-dialog
    #responseDialog
    title="{{ undefined }}"
    confirmBtnLabel="{{ undefined }}"
    (closeClicked)="closeClicked()"
></confirm-dialog>

<!-- Custom dialogs -->
<confirm-dialog #reassignBrokerDialog confirmBtnLabel="Accept" (confirmClicked)="confirmReassignBroker()">
    <div class="row justify-content-center m-3" *ngIf="!loadingDetails">
        <h1 data-testid="organisationName">{{ organisation?.name }}</h1>
        <mat-select
            [(value)]="selectedOriginatorCode"
            class="border-bottom border-dark"
            data-testid="selectReassignOriginator"
        >
            <mat-option
                *ngFor="let orgOriginator of organisationOriginators"
                [value]="orgOriginator.code"
                [disabled]="orgOriginator.code === originator.code"
                [attr.data-testid]="'originator-' + orgOriginator.code"
            >
                {{ orgOriginator.individual }} <span *ngIf="orgOriginator.code === originator.code">*</span>
            </mat-option>
        </mat-select>
    </div>
    <div *ngIf="loadingDetails" class="row justify-content-center my-3">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</confirm-dialog>
