import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { RouterModule, Routes } from '@angular/router';
import { FormatDateComponent } from '@pa/lib-spa';

import { AuthGuard } from '../core/auth/auth.guard';
import { ClaimsPage } from './claims.page';
import { ClaimsServiceModule } from './claims.service.module';

const routes: Routes = [
    {
        path: 'claims',
        component: ClaimsPage,
        canActivate: [AuthGuard]
    },
];

@NgModule({
    imports: [
        CommonModule,
        ClaimsServiceModule,
        MatCardModule,
        MatListModule,
        FormatDateComponent,

        RouterModule.forChild(routes)
    ],
    declarations: [
        ClaimsPage
    ]
})
export class ClaimsModule {}
